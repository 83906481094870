<template>
  <div>
    <v-card v-if="!$vuetify.breakpoint.smAndDown">
      <v-system-bar>Pendente Rechnungen</v-system-bar>
      <InvoiceTable :items="itemsFiltered" page="InvoicePending" />
    </v-card>
    <template v-else
      ><InvoiceCards :items="itemsFiltered" page="InvoicePending" />
    </template>
  </div>
</template>
<script>
import InvoiceCards from "./Components/InvoiceCards.vue";
import InvoiceTable from "./Components/InvoiceTable.vue";

export default {
  components: { InvoiceCards, InvoiceTable },
  props: {
    search: { type: String },
  },
  data() {
    return {
      items: [],
    };
  },
  computed: {
    itemsFiltered() {
      return this.items.filter(
        (item) =>
          item.event.toLowerCase().includes(this.search.toLowerCase()) ||
          item.description.toLowerCase().includes(this.search.toLowerCase())
      );
    },
  },
  methods: {
    async fetchData() {
      this.loading = true;
      this.items = await this.apiList({
        resource: "finance/invoice",
        query: "status=created",
      });
      this.items = this.items.concat(
        await this.apiList({
          resource: "finance/invoice",
          query: "status=rejected",
        })
      );
      this.loading = false;
    },
  },
  async created() {
    this.fetchData();
  },
};
</script>
